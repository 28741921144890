import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import {AppProperties} from './shared/app.properties';
import {UtilityService} from './shared/services/utility.service';
import { ServicesComponent } from './services/services.component';
import {HttpClientModule} from '@angular/common/http';
import {AppConstants} from './shared/app.constants';
import { UnderComponent } from './components/under/under/under.component';
import {TranslatorService} from './shared/services/translator.service';
import {ThumbnailComponent} from './thumbnail/thumbnail.component';
import { DivisionComponent } from './components/division/division.component';
import { BytrendsComponent } from './home/bytrends/bytrends.component';
import { ByeventsComponent } from './home/byevents/byevents.component';
import { BythemesComponent } from './home/bythemes/bythemes.component';
import { PartnersComponent } from './partners/partners.component';
import {SocialMediaComponent} from './components/socialmedia/socialmedia.component';
import { HashLocationStrategy, LocationStrategy} from '@angular/common';
import { TiarasComponent } from './tiaras/tiaras.component';
import { CtaComponent } from './components/cta/cta.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    AboutComponent,
    HomeComponent,
    ServicesComponent,
    UnderComponent,
    ThumbnailComponent,
    DivisionComponent,
    BytrendsComponent,
    ByeventsComponent,
    BythemesComponent,
    PartnersComponent,
    SocialMediaComponent,
    TiarasComponent,
    CtaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [AppProperties, AppConstants, UtilityService, TranslatorService, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
