import { Component, OnInit } from '@angular/core';
import {TranslatorService} from '../shared/services/translator.service';

interface MenuItem {
  path: string;
  description: string;
  index: number;
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  copyright: string;
  follow_us: string;

  menuItems_ads: MenuItem[];

  constructor(private translator: TranslatorService) {
    this.copyright = ' 2019';
    this.follow_us = 'Follow us on Social Media';
  }

  ngOnInit() {
      this.translator.getMenuItems().subscribe( (data: any) => {
        this.menuItems_ads = <MenuItem[]> data.menuItems_ads;
      }, (error) => {
        console.log('Error  Handler ', error);
      });
  }

}
