import { Component, OnInit } from '@angular/core';
import {AppProperties} from '../shared/app.properties';
import {TranslatorService} from '../shared/services/translator.service';

interface MenuItem {
  path: string;
  description: string;
  index: number;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  logoPath: string;
  menuItems: MenuItem[];
  menuItems_ads: MenuItem[];
  selectedItem: MenuItem;

  constructor(
    private translator: TranslatorService,
    private appProps: AppProperties) {
    this.logoPath = appProps.LOGO_PATH;
  }

  ngOnInit() {

    this.translator.getMenuItems().subscribe( (data: any) => {
      this.menuItems = <MenuItem[]> data.menuItems;
      this.menuItems_ads = <MenuItem[]> data.menuItems_ads;
    }, (error) => {
      console.log('Error  Handler ', error);
    });
  }

  /**
   * Stores the selected menu item temporarily
   * @param item Selected Item
   */
  selectItem(item: MenuItem) {
    this.selectedItem = item;
  }

}
