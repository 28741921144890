import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AboutComponent} from './about/about.component';
import {HomeComponent} from './home/home.component';
import {ServicesComponent} from './services/services.component';
import {UnderComponent} from './components/under/under/under.component';
import {TiarasComponent} from './tiaras/tiaras.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'services', component: UnderComponent },
  { path: 'about', component: AboutComponent},
  { path: 'tiaras', component: TiarasComponent},
  { path: 'under', component: UnderComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
