import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  constructor(private http: HttpClient) {
  }

  getText() {
    return this.http.get('./assets/content/i18n.json');
  }
  getMenuItems() {
    return this.http.get('./assets/content/navigation.json');
  }
}
