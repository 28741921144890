import { Injectable } from '@angular/core';
import { AppConstants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class UtilityService extends AppConstants {

  constructor() {
    super();
  }

  getLoremIpsum(size: number): string {
    return this.LOREMIPSUM.substr(0, size);
  }

}
