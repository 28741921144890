export class AppConstants {

  constructor() {}

  public SIZE = {
    XSMALL: 50,
    SMALL: 100,
    MEDIUM: 250,
    LARGE: 500,
    XLARGE: 1000
  };

  public LOCALE = {
    EN_US: 'en_US',
    ES_MX: 'es_MX'
  };
  public LOREMIPSUM =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna'
    + 'aliqua. Gravida quis blandit turpis cursus. Fermentum et sollicitudin ac orci phasellus egestas tellus rutrum. '
    + 'Aliquam ultrices sagittis orci a. Molestie a iaculis at erat pellentesque. Leo duis ut diam quam nulla porttitor '
    + 'massa id. Velit aliquet sagittis id consectetur. Velit laoreet id donec ultrices tincidunt arcu non. Lacus '
    + 'viverra vitae congue eu consequat ac felis donec. Laoreet suspendisse interdum consectetur libero.\n\n'
    + 'Massa massa ultricies mi quis hendrerit dolor magna eget. Ac turpis egestas integer eget aliquet. Feugiat vivamus'
    + 'at augue eget arcu dictum varius duis at. Placerat orci nulla pellentesque dignissim enim. Neque vitae tempus '
    + 'quam pellentesque nec nam aliquam. Volutpat diam ut venenatis tellus in metus vulputate eu scelerisque. Nisl '
    + 'condimentum id venenatis a condimentum vitae. Viverra maecenas accumsan lacus vel facilisis. In arcu cursus '
    + 'euismod quis viverra. Integer malesuada nunc vel risus commodo viverra maecenas.\n\n'
    + 'Volutpat consequat mauris nunc congue. Facilisis gravida neque convallis a cras semper auctor neque vitae. '
    + 'Ultrices sagittis orci a scelerisque purus semper eget. Tellus rutrum tellus pellentesque eu tincidunt tortor '
    + 'aliquam nulla facilisi. Sit amet nisl suscipit adipiscing bibendum. Lorem dolor sed viverra ipsum nunc. Interdum '
    + 'varius sit amet mattis. Scelerisque eleifend donec pretium vulputate sapien nec sagittis aliquam. Sodales neque '
    + 'sodales ut etiam. Porttitor eget dolor morbi non arcu risus quis varius. Sed ullamcorper morbi tincidunt ornare '
    + 'massa eget egestas. Quisque non tellus orci ac auctor. Vulputate sapien nec sagittis aliquam. Lacus vestibulum '
    + 'sed arcu non odio. Mattis aliquam faucibus purus in massa.';
}
