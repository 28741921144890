import { Component, OnInit } from '@angular/core';
import {UtilityService} from '../shared/services/utility.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public title: string;
  public utility: UtilityService;

  constructor(utility: UtilityService) {
    this.title = 'App Shell';
    this.utility = utility;
  }

  ngOnInit() {
  }
}
