import { Component, OnInit } from '@angular/core';
import {UtilityService} from '../shared/services/utility.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  utility: UtilityService;
  about_title: string;

  constructor(utility: UtilityService) {
    this.utility = utility;
    this.about_title = 'About component';
  }

  /**
   *
   */
  ngOnInit() {
  }
}
