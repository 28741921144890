import { Component, OnInit } from '@angular/core';
import {UtilityService} from '../shared/services/utility.service';
import {TranslatorService} from '../shared/services/translator.service';
import {AppConstants} from '../shared/app.constants';
import {AppProperties} from '../shared/app.properties';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  pageTextObj: any;
  author: string;
  language: string;


  constructor(
    private translator: TranslatorService,
    private constants: AppConstants,
    private appProps: AppProperties,
    public utility: UtilityService) {
      this.language = this.appProps.DEFAULT_LANG;
  }

  /**
   */
  ngOnInit() {
    // Call translator to get the text based on the default language
    this.reloadText();
  }

  /**
   * Will reload the text based on the selected language
   */
  reloadText() {
    this.translator.getText().subscribe((data) => {
      this.pageTextObj = data[this.language];
      this.author = this.getAuthor();
    });
  }

  /**
   * @param newLanguage - New Language selected
   */
  switchLanguage(newLanguage: string) {
    this.language = newLanguage;
    this.reloadText();
  }
  /**
    */
  private getAuthor(): string {
    const author = '';
    if (this.pageTextObj !== undefined) {
      return this.pageTextObj.author.firstName.concat(' ', this.pageTextObj.author.lastName);
    }
    return author;
  }

}
