import { Component, OnInit } from '@angular/core';
import {UtilityService} from '../../shared/services/utility.service';

@Component({
  selector: 'app-bytrends',
  templateUrl: './bytrends.component.html',
  styleUrls: ['./bytrends.component.scss']
})
export class BytrendsComponent implements OnInit {

  public title: string;
  public utility: UtilityService;
  constructor(utility: UtilityService) {
    this.title = 'App Shell';
    this.utility = utility;
  }


  ngOnInit() {
  }

}
